import { Container } from "@mui/material";

import useSettings from "../../hooks/useSettings";
// routes
// components
import Page from "../../components/common/Page";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";

export default function TestResult() {
  const { themeStretch } = useSettings();
  return (
    <Page title="Users">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading="Test Results"
          links={[{ name: "Test result", href: "/test/result" }]}
        />
      </Container>
    </Page>
  );
}
