import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Segment,
  Item,
  Dropdown,
  Divider,
  Button,
  Message,
} from "semantic-ui-react";

import { shuffle } from "../../../utils";

import Offline from "../Offline";
import {
  CATEGORIES,
  NUM_OF_QUESTIONS,
  DIFFICULTY,
  QUESTIONS_TYPE,
  COUNTDOWN_TIME,
} from "../../../constants";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const Main = ({ startQuiz }) => {
  const [category, setCategory] = useState("0");
  const [numOfQuestions, setNumOfQuestions] = useState(5);
  const [difficulty, setDifficulty] = useState("0");
  const [questionsType, setQuestionsType] = useState("0");
  const [countdownTime, setCountdownTime] = useState({
    hours: 0,
    minutes: 120,
    seconds: 0,
  });
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [offline, setOffline] = useState(false);

  const handleTimeChange = (e, { name, value }) => {
    setCountdownTime({ ...countdownTime, [name]: value });
  };

  let allFieldsSelected = false;
  if (
    category &&
    numOfQuestions &&
    difficulty &&
    questionsType &&
    (countdownTime.hours || countdownTime.minutes || countdownTime.seconds)
  ) {
    allFieldsSelected = true;
  }

  const fetchData = () => {
    setProcessing(true);

    if (error) setError(null);

    const API = `https://backend.judges.uz/api/v1/tests/tests-list?limit=${20}&difficulty=${difficulty}&sportType=${questionsType}`;

    fetch(API)
      .then((respone) => respone.json())
      .then((data) =>
        setTimeout(() => {
          const { response_code, results } = data;
          if (response_code === 1) {
            const message = (
              <p>
                The API doesn't have enough questions for your query. (Ex.
                Asking for 50 Questions in a Category that only has 20.)
                <br />
                <br />
                Please change the <strong>No. of Questions</strong>,{" "}
                <strong>Difficulty Level</strong>, or{" "}
                <strong>Type of Questions</strong>.
              </p>
            );

            setProcessing(false);
            setError({ message });

            return;
          }

          setProcessing(false);
          startQuiz(
            results,
            countdownTime.hours + countdownTime.minutes + countdownTime.seconds
          );
        }, 1000)
      )
      .catch((error) =>
        setTimeout(() => {
          if (!navigator.onLine) {
            setOffline(true);
          } else {
            setProcessing(false);
            setError(error);
          }
        }, 1000)
      );
  };

  if (offline) return <Offline />;

  return (
    <>
      <Segment style={{ border: "none", paddingRight: 100, boxShadow: "none" }}>
        <Item.Group divided>
          <Item>
            <Item.Image />
            <Item.Content>
              <Item.Header>
                <h1>Testing</h1>
              </Item.Header>
              {error && (
                <Message error onDismiss={() => setError(null)}>
                  <Message.Header>Error!</Message.Header>
                  {error.message}
                </Message>
              )}
              {/*<Divider />*/}
              <Item.Meta>
                <Typography>Number of tests</Typography>
                <Box
                  sx={{
                    border: "1px solid rgba(34,36,38,.15)",
                    height: 38,
                    display: "flex",
                    alignItems: "center",
                    padding: "11px 14px",
                    borderRadius: ".28571429rem",
                  }}
                >
                  20
                </Box>
                <br />
                <Typography>Given time</Typography>
                <Box
                  sx={{
                    border: "1px solid rgba(34,36,38,.15)",
                    height: 38,
                    display: "flex",
                    alignItems: "center",
                    padding: "11px 14px",
                    borderRadius: ".28571429rem",
                  }}
                >
                  20 minutes
                </Box>
                <br />
                <Typography>Select Sport type</Typography>
                <Dropdown
                  fluid
                  selection
                  name="category"
                  placeholder="Select Quiz Category"
                  header="Select Quiz Category"
                  options={CATEGORIES}
                  value={category}
                  onChange={(e, { value }) => setCategory(value)}
                  disabled={processing}
                />
                <br />
                <Typography>Select Difficulty</Typography>
                <Dropdown
                  fluid
                  selection
                  name="difficulty"
                  placeholder="Select Difficulty Level"
                  header="Select Difficulty Level"
                  options={DIFFICULTY}
                  value={difficulty}
                  onChange={(e, { value }) => setDifficulty(value)}
                  disabled={processing}
                />
                <br />
                {/*<Dropdown*/}
                {/*  fluid*/}
                {/*  selection*/}
                {/*  name="type"*/}
                {/*  placeholder="Select Questions Type"*/}
                {/*  header="Select Questions Type"*/}
                {/*  options={QUESTIONS_TYPE}*/}
                {/*  value={questionsType}*/}
                {/*  onChange={(e, { value }) => setQuestionsType(value)}*/}
                {/*  disabled={processing}*/}
                {/*/>*/}
                {/*<Dropdown*/}
                {/*  search*/}
                {/*  selection*/}
                {/*  name="hours"*/}
                {/*  placeholder="Select Hours"*/}
                {/*  header="Select Hours"*/}
                {/*  options={COUNTDOWN_TIME.hours}*/}
                {/*  value={countdownTime.hours}*/}
                {/*  onChange={handleTimeChange}*/}
                {/*  disabled={processing}*/}
                {/*/>*/}
                {/*<Dropdown*/}
                {/*  search*/}
                {/*  selection*/}
                {/*  name="minutes"*/}
                {/*  placeholder="Select Minutes"*/}
                {/*  header="Select Minutes"*/}
                {/*  options={COUNTDOWN_TIME.minutes}*/}
                {/*  value={countdownTime.minutes}*/}
                {/*  onChange={handleTimeChange}*/}
                {/*  disabled={processing}*/}
                {/*/>*/}
                {/*<Dropdown*/}
                {/*  search*/}
                {/*  selection*/}
                {/*  name="seconds"*/}
                {/*  placeholder="Select Seconds"*/}
                {/*  header="Select Seconds"*/}
                {/*  options={COUNTDOWN_TIME.seconds}*/}
                {/*  value={countdownTime.seconds}*/}
                {/*  onChange={handleTimeChange}*/}
                {/*  disabled={processing}*/}
                {/*/>*/}
              </Item.Meta>
              <Item.Extra>
                <Button
                  primary
                  size="big"
                  icon="play"
                  labelPosition="left"
                  content={processing ? "Processing..." : "Start Test"}
                  onClick={fetchData}
                  disabled={!allFieldsSelected || processing}
                />
              </Item.Extra>
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
      <br />
    </>
  );
};

Main.propTypes = {
  startQuiz: PropTypes.func.isRequired,
};

export default Main;
