import React from "react";
import { Container } from "@mui/material";
import useSettings from "../../hooks/useSettings";
import { PATH_DASHBOARD } from "../../routes/paths";
import Page from "../../components/common/Page";
import TestForm from "./TestForm";
import Typography from "@mui/material/Typography";
import { useLoad, usePutRequest } from "../../hooks/request";
import { TEST_CREATE, TEST_ITEM } from "../../urls";
import { useNavigate, useParams } from "react-router-dom";

import { useSnackbar } from "notistack";
export default function TestUpdate() {
  const { id } = useParams();
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const create = usePutRequest({ url: TEST_ITEM.replace("{id}", id) });
  const testData = useLoad({ url: TEST_ITEM.replace("{id}", id) });
  const data = testData.response ? testData.response : [];
  console.log(data);
  async function onSubmit(data, actions) {
    const { success } = await create.request({ data });

    if (success) {
      enqueueSnackbar("Update success", {
        variant: "success",
      });
      navigate("/test/list");
      actions.resetForm();
    }
  }
  const initialValues = {
    questionName: data.questionName,
    image: data.image,
    level: data.level,
    sportType: data.sportType,
    correctAnswer: data.level === 3 ? data.correctAnswer : undefined,
    lvlOne: data.level === 1 ? data.correctAnswer : undefined,
    lvlTwo: data.level === 2 ? data.correctAnswer : undefined,
    answers:
      data && data.answers
        ? data.answers.map((answer, index) => ({
            label: `Answer ${index + 1}`,
            value: answer,
          }))
        : [],
  };
  return (
    <Page title="Test">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <Typography variant="h3">Test Form</Typography>
        <TestForm
          onSubmit={onSubmit}
          loading={create.loading}
          initialValues={initialValues}
        />
      </Container>
    </Page>
  );
}
