import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSnackbar } from "notistack";
import { Button, Fade } from "@mui/material";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import { PROTOCOL_TYPE_ITEM } from "../../urls";
import { useDeleteRequest } from "../../hooks/request";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  border: "none",
  outline: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export default function DeleteProtocolTypeModal({
  open,
  handleClose,
  selectedRow,
  onUpdate,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const remove = useDeleteRequest({
    url: PROTOCOL_TYPE_ITEM.replace("{id}", selectedRow.id),
  });
  async function Delete() {
    const { error, success } = await remove.request();
    if (success) {
      handleClose();
      onUpdate();
      enqueueSnackbar("Deleted successfully", {
        variant: "success",
      });
    }
    if (error) {
      enqueueSnackbar(error.data.detail, {
        variant: "error",
      });
    }
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Fade in={open}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h4">
                Are you sure you want to delete?
              </Typography>
              <Box
                sx={{
                  mt: 4,
                }}
              >
                <LoadingButton
                  loading={remove.loading}
                  variant="contained"
                  color="secondary"
                  onClick={() => Delete()}
                  sx={{ mr: 3 }}
                >
                  Yes, delete
                </LoadingButton>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                >
                  No, cancel
                </Button>
              </Box>
            </Box>
          </Fade>
        </Box>
      </Modal>
    </div>
  );
}
