import PropTypes from 'prop-types'
//
import SettingsDrawer from './drawer'
//
import ThemeColorPresets from './ThemeColorPresets'
import ThemeLocalization from './ThemeLocalization'

// ----------------------------------------------------------------------

ThemeSettings.propTypes = {
    children: PropTypes.node.isRequired,
}

export default function ThemeSettings({ children }) {
    return (
        <ThemeColorPresets>
            <ThemeLocalization>
                {children}
                <SettingsDrawer />
            </ThemeLocalization>
        </ThemeColorPresets>
    )
}
