import { Container } from "@mui/material";

import useSettings from "../../hooks/useSettings";
// routes

// components
import Page from "../../components/common/Page";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import ProtocolTypeTable from "../../components/protocolType/ProtocolTypeTable";

export default function ProtocolType() {
  const { themeStretch } = useSettings();
  return (
    <Page title="Users">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading="Protocol names"
          links={[{ name: "Protocol names", href: "protocol-type" }]}
        />
        <ProtocolTypeTable />
      </Container>
    </Page>
  );
}
