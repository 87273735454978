import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Segment,
  Item,
  Divider,
  Button,
  Icon,
  Message,
  Menu,
  Header,
  Grid,
} from "semantic-ui-react";

import Countdown from "../Countdown";

const Quiz = ({ data, countdownTime, endQuiz }) => {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [userSelectedAns, setUserSelectedAns] = useState(null);
  const [userResponses, setUserResponses] = useState([]);
  const [timeTaken, setTimeTaken] = useState(0);

  const handleItemClick = (e, { name }) => {
    setUserSelectedAns(name);
  };
  console.log(userResponses);
  const handleNext = () => {
    const currentResponse = {
      answer: userSelectedAns,
      test: data[questionIndex].id,
    };

    setUserResponses((prevResponses) => [...prevResponses, currentResponse]);

    // Check if current question is the last one
    if (questionIndex === data.length - 1) {
      // Call endQuiz
      endQuiz({
        userResponses: [...userResponses, currentResponse],
        totalQuestions: data.length,
        timeTaken,
        questionsAndAnswers: [...userResponses, currentResponse],
      });
    } else {
      setQuestionIndex(questionIndex + 1);
    }

    setUserSelectedAns(null);
  };

  const timeOver = (timeTaken) => {
    return endQuiz({
      totalQuestions: data.length,
      timeTaken: timeTaken,
      questionsAndAnswers: userResponses,
    });
  };
  return (
    <Item.Header style={{ overflow: "hidden", height: "100vh" }}>
      {" "}
      {/* Adding overflow hidden and full height */}
      <Container>
        <Segment>
          <Item.Group divided>
            <Item>
              <Item.Content>
                <Item.Extra>
                  <Header as="h1" block floated="left">
                    <Icon name="info circle" />
                    <Header.Content>
                      {`Question No.${questionIndex + 1} of ${data.length}`}
                    </Header.Content>
                  </Header>
                  <Countdown
                    setTimeTaken={setTimeTaken}
                    countdownTime={countdownTime}
                    timeOver={timeOver}
                  />
                </Item.Extra>
                <br />
                <Item.Meta>
                  <Message size="huge" floating>
                    <b>{`Q. ${data[questionIndex].question_name}`}</b>
                  </Message>
                  <br />
                  {data[questionIndex] && data[questionIndex].image && (
                    <img
                      src={data[questionIndex].image}
                      alt="question-related"
                      style={{ maxWidth: "100%", height: "400px" }}
                    />
                  )}
                  <Item.Description>
                    <h3>Please choose one of the following answers:</h3>
                  </Item.Description>
                  <Divider />
                  <Grid padded>
                    {" "}
                    {data[questionIndex] &&
                      data[questionIndex].answers &&
                      data[questionIndex].answers.map((option, i) => (
                        <Grid.Column key={i} width={5}>
                          {" "}
                          <Button
                            toggle
                            className="btn btn-primary"
                            active={userSelectedAns === option}
                            onClick={(e) =>
                              handleItemClick(e, { name: option })
                            }
                          >
                            {option}
                          </Button>
                        </Grid.Column>
                      ))}
                  </Grid>
                </Item.Meta>
                <Divider />
                <Item.Extra>
                  <Button
                    primary
                    content={
                      questionIndex === data.length - 1 ? "Finish" : "Next"
                    }
                    onClick={handleNext}
                    floated="right"
                    size="big"
                    icon="right chevron"
                    labelPosition="right"
                    disabled={!userSelectedAns}
                  />
                </Item.Extra>
              </Item.Content>
            </Item>
          </Item.Group>
        </Segment>
        <br />
      </Container>
    </Item.Header>
  );
};

Quiz.propTypes = {
  data: PropTypes.array.isRequired,
  countdownTime: PropTypes.number.isRequired,
  endQuiz: PropTypes.func.isRequired,
};

export default Quiz;
