import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Menu } from "semantic-ui-react";

import Stats from "./Stats";
import QNA from "./QNA";
import { usePostRequest } from "../../../hooks/request";

const Result = ({
  totalQuestions,
  timeTaken,
  questionsAndAnswers,
  replayQuiz,
  resetQuiz,
  userResponses,
}) => {
  const [activeTab, setActiveTab] = useState("Stats");
  const items = JSON.parse(localStorage.getItem("user"));

  const {
    request: sendPostRequest,
    response: serverResponse,
    error: postRequestError,
  } = usePostRequest();

  useEffect(() => {
    const dataToSend = { answers: questionsAndAnswers, user: items.id };
    sendPostRequest({ url: "/tests/solve-test", data: dataToSend });
  }, []); // Empty array ensures this runs only once on component mount

  const [serverData, setServerData] = useState(null);

  useEffect(() => {
    if (serverResponse) {
      setServerData(serverResponse);
    }
  }, [serverResponse]);

  useEffect(() => {
    if (postRequestError) {
      console.error(postRequestError);
      // Handle error appropriately
    }
  }, [postRequestError]);

  const handleTabClick = (e, { name }) => {
    setActiveTab(name);
  };
  console.log(serverData);
  return (
    <Container>
      {/*<Menu fluid widths={2}>*/}
      {/*  <Menu.Item*/}
      {/*    name="Stats"*/}
      {/*    active={activeTab === "Stats"}*/}
      {/*    onClick={handleTabClick}*/}
      {/*  />*/}
      {/*  <Menu.Item*/}
      {/*    name="QNA"*/}
      {/*    active={activeTab === "QNA"}*/}
      {/*    onClick={handleTabClick}*/}
      {/*  />*/}
      {/*</Menu>*/}
      {activeTab === "Stats" && serverData && (
        <Stats
          percentage={serverData.percentage ?? ""}
          correct={serverData.score ?? ""}
          totalQuestions={totalQuestions}
          timeTaken={timeTaken}
          replayQuiz={replayQuiz}
          resetQuiz={resetQuiz}
        />
      )}
      {activeTab === "QNA" && <QNA questionsAndAnswers={questionsAndAnswers} />}
      <br />
    </Container>
  );
};

Result.propTypes = {
  totalQuestions: PropTypes.number.isRequired,
  timeTaken: PropTypes.number.isRequired,
  questionsAndAnswers: PropTypes.array.isRequired,
  replayQuiz: PropTypes.func.isRequired,
  resetQuiz: PropTypes.func.isRequired,
};

export default Result;
