const DIFFICULTY = [
  {
    key: '0',
    text: 'Any Difficulty',
    value: '0'
  },
  {
    key: 'easy',
    text: 'Easy',
    value: 'easy'
  },
  {
    key: 'medium',
    text: 'Medium',
    value: 'medium'
  },
  {
    key: 'hard',
    text: 'Hard',
    value: 'hard'
  }
];

export default DIFFICULTY;
