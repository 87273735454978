import React, { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
import { PATH_AFTER_LOGIN } from "../config";
import LoadingScreen from "../components/common/LoadingScreen";
import { MainQuiz } from "../components/quiz/MainQuiz";
import ProtocolTypeCreate from "../components/protocolType/ProtocolTypeCreate";
import ProtocolType from "../pages/protocolType/ProtocolType";
import ProtocolTypeUpdate from "../components/protocolType/ProtocolTypeUpdate";
import ProtocolCardsList from "../pages/protocol/ProtocolCardsList";
import TestResult from "../pages/testResult/TestResult";
import CompetitionList from "../components/competition/CompetitionList";
import TestUpdate from "../components/testing/TestUpdate";

const Loadable = (Component) =>
  function (props) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
      <Suspense
        fallback={
          <LoadingScreen isDashboard={pathname.includes("/dashboard")} />
        }
      >
        <Component {...props} />
      </Suspense>
    );
  };

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "register",
          element: <Register />,
        },
        { path: "login-unprotected", element: <Login /> },
        { path: "register-unprotected", element: <Register /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "new-password", element: <NewPassword /> },
        { path: "verify", element: <VerifyCode /> },
      ],
    },
    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "app", element: <GeneralApp /> },
        {
          path: "user",
          children: [
            {
              element: <Navigate to="/dashboard/user/profile" replace />,
              index: true,
            },
            { path: "profile", element: <UserProfile /> },
            { path: "account", element: <UserAccount /> },
          ],
        },
      ],
    },
    {
      path: "competition",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: "", element: <CompetitionList /> },
        { path: "list", element: <CompetitionList /> },
        { path: "list/:id", element: <Competition /> },
        { path: "result", element: <CompetitionResult /> },
        { path: "result-c3", element: <CompetitionResultC3 /> },
        { path: "select/:id", element: <CompetitionSelect /> },
        { path: "select-type", element: <CompetitionTypeSelect /> },
      ],
    },
    {
      path: "user",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/user/all-user" replace />, index: true },
        { path: "all-user", element: <Users /> },
        { path: "create", element: <CreateUser /> },
        { path: "update/:id", element: <UpdateUser /> },
      ],
    },
    {
      path: "protocol",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: "", element: <Protocol /> },
        { path: "create", element: <ProtocolCreate /> },
        { path: "update/:id", element: <ProtocolUpdate /> },
        { path: "list", element: <ProtocolCardsList /> },
        { path: "list/:id", element: <ProtocolList /> },
        { path: "protocol-detail", element: <ProtocolSelect /> },
        { path: "protocol-team", element: <ProtocolC4 /> },
        { path: "team-create", element: <Team /> },
        { path: "assessment", element: <Assessment /> },
        { path: "competition-select/:id", element: <ProtocolCompetition /> },
      ],
    },
    {
      path: "test",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: "testing", element: <MainQuiz /> },
        { path: "create", element: <CreateTest /> },
        { path: "update/:id", element: <TestUpdate /> },
        { path: "list", element: <TestTable /> },
        { path: "result", element: <TestResult /> },
      ],
    },
    {
      path: "education",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: "", element: <Education /> }],
    },
    {
      path: "under-construct",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: "", element: <UnderConstruct /> }],
    },
    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "protocol-type",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: "", element: <ProtocolType /> },
        { path: "create", element: <ProtocolTypeCreate /> },
        { path: "update/:id", element: <ProtocolTypeUpdate /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
const NewPassword = Loadable(lazy(() => import("../pages/auth/NewPassword")));
const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));

const UserProfile = Loadable(
  lazy(() => import("../pages/dashboard/UserProfile"))
);
const UserAccount = Loadable(
  lazy(() => import("../pages/dashboard/UserAccount"))
);

// GENERAL
const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);
// COMPETITION
const Competition = Loadable(
  lazy(() => import("../pages/competition/Competition"))
);
const CompetitionResult = Loadable(
  lazy(() => import("../pages/competition/CompetitionResults"))
);
const CompetitionResultC3 = Loadable(
  lazy(() => import("../pages/competition/CompetitionC3Result"))
);
const CompetitionSelect = Loadable(
  lazy(() => import("../pages/competition/CompetitionSelect"))
);
const CompetitionTypeSelect = Loadable(
  lazy(() => import("../pages/competition/CompetitionTypeSelect"))
);
// USER
const Users = Loadable(lazy(() => import("../pages/user/Users")));
const CreateUser = Loadable(lazy(() => import("../pages/user/UserCreate")));
const UpdateUser = Loadable(lazy(() => import("../pages/user/UserUpdate")));
// PROTOCOLS
const Protocol = Loadable(lazy(() => import("../pages/protocol/Protocol")));
const ProtocolCreate = Loadable(
  lazy(() => import("../components/protocol/ProtocolCreate"))
);
const ProtocolUpdate = Loadable(
  lazy(() => import("../components/protocol/ProtocolUpdate"))
);
const ProtocolSelect = Loadable(
  lazy(() => import("../pages/protocol/ProtocolTypeSelect"))
);
const ProtocolC4 = Loadable(
  lazy(() => import("../pages/protocol/ProtocolTeam"))
);
const Team = Loadable(
  lazy(() => import("../components/protocol/ProtocolTeamCreate"))
);
const TeamUpdate = Loadable(
  lazy(() => import("../components/protocol/ProtocolTeamCreate"))
);
const ProtocolList = Loadable(
  lazy(() => import("../pages/protocol/ProtocolsList"))
);
const Assessment = Loadable(
  lazy(() => import("../pages/protocol/ProtocolAssessment"))
);
const ProtocolCompetition = Loadable(
  lazy(() => import("../pages/protocol/ProtocolCompetitionSelect"))
);
// TESTING
const JudgesTest = Loadable(
  lazy(() => import("../pages/judgeTesting/JudgeTesting"))
);
const CreateTest = Loadable(
  lazy(() => import("../components/testing/TestCreate"))
);
const TestTable = Loadable(
  lazy(() => import("../components/testing/TestList"))
);
// EDUCATION
const Education = Loadable(lazy(() => import("../pages/education/Education")));

const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const Page403 = Loadable(lazy(() => import("../pages/Page403")));
const Page404 = Loadable(lazy(() => import("../pages/Page404")));
const UnderConstruct = Loadable(lazy(() => import("../pages/UnderConstruct")));
