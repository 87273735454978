const CATEGORIES = [
  {
    key: "0",
    text: "Any Category",
    value: "0",
  },
  {
    key: 9,
    text: "FX",
    value: 9,
  },
  {
    key: 10,
    text: "PH",
    value: 10,
  },
  {
    key: 11,
    text: "SR",
    value: 11,
  },
  {
    key: 12,
    text: "VT",
    value: 12,
  },
  {
    key: 13,
    text: "PB",
    value: 13,
  },
  {
    key: 14,
    text: "HB",
    value: 14,
  },
];

export default CATEGORIES;
