import PropTypes from 'prop-types'
import { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
// hooks
// pages
// components
import { isAuthenticated } from '../utils/auth'
import Login from '../pages/auth/Login'

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
    children: PropTypes.node,
}

export default function AuthGuard({ children }) {
    const { pathname } = useLocation()

    const [requestedLocation, setRequestedLocation] = useState(null)
    //
    // if (!isInitialized) {
    //   return <LoadingScreen />;
    // }

    if (!isAuthenticated()) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname)
        }
        return <Login />
    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null)
        return <Navigate to={requestedLocation} />
    }

    return <>{children}</>
}
