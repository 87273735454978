// hooks
import { useSelector } from "react-redux";
// utils
import createAvatar from "../../utils/createAvatar";
//
import Avatar from "./Avatar";

// ----------------------------------------------------------------------

export default function MyAvatar({ user }) {
  return (
    <Avatar
      src={"https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"}
      alt={user.name}
      color={user?.avatar ? "default" : createAvatar(user?.name).color}
    >
      {createAvatar(user?.name).name}
    </Avatar>
  );
}
