import React from "react";
import { Container } from "@mui/material";
import useSettings from "../../hooks/useSettings";
import Page from "../../components/common/Page";
import Typography from "@mui/material/Typography";
import { usePostRequest } from "../../hooks/request";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import ProtocolTypeForm from "./ProtocolTypeForm";

export default function ProtocolTypeCreate() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const create = usePostRequest({ url: "/athletes/protocol-type" });

  async function onSubmit(data, actions) {
    const { success } = await create.request({ data });

    if (success) {
      navigate("/protocol-type");
      enqueueSnackbar("Create success", {
        variant: "success",
      });
      actions.resetForm();
    }
  }
  return (
    <Page title="Test">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <Typography variant="h3">Form</Typography>
        <ProtocolTypeForm onSubmit={onSubmit} loading={create.loading} />
      </Container>
    </Page>
  );
}
