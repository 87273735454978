import { Helmet } from "react-helmet-async";
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  Divider,
  Stack,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Checkbox,
  Alert,
  Link,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import { useContext, useEffect, useState } from "react";
import { usePostRequest } from "../../hooks/request";
import { SIGNIN } from "../../urls";
import useResponsive from "../../hooks/useResponsive";
import { isAuthenticated, signin } from "../../utils/auth";
import Logo from "../../components/common/Logo";
import Iconify from "../../components/common/Iconify";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import useIsMountedRef from "../../hooks/useIsMountedRef";
import { PATH_AUTH } from "../../routes/paths";
import { GlobalContext } from "../../contexts/GlobalContext";
// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledSection = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 480,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const { setUserType } = useContext(GlobalContext);
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const mdUp = useResponsive("up", "md");

  const navigate = useNavigate();
  const signIn = usePostRequest({
    url: SIGNIN,
    headers: {},
  });
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/dashboard/app");
    }
  }, [navigate]);

  async function onSubmit(data) {
    const { response, success, error } = await signIn.request({ data });
    if (error) {
      enqueueSnackbar(error.statusText, {
        variant: "error",
      });
    }
    if (success) {
      setUserType(response.user.userType);
      enqueueSnackbar("Login success", {
        variant: "success",
      });
      navigate("/dashboard/app");
      signin(response, navigate);
    }
  }
  return (
    <>
      <Helmet>
        <title>Login Judges.uz</title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: "fixed",
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img
              src="/assets/illustrations/illustration_login.png"
              alt="login"
            />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Sign in
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              Don’t have an account?
              <Link
                variant="subtitle2"
                sx={{ ml: "5px" }}
                component={RouterLink}
                to={PATH_AUTH.register}
              >
                Get started
              </Link>
            </Typography>
            {/*<Stack direction="row" spacing={2}>*/}
            {/*  <Button fullWidth size="large" color="inherit" variant="outlined">*/}
            {/*    <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />*/}
            {/*  </Button>*/}

            {/*  <Button fullWidth size="large" color="inherit" variant="outlined">*/}
            {/*    <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />*/}
            {/*  </Button>*/}

            {/*  <Button fullWidth size="large" color="inherit" variant="outlined">*/}
            {/*    <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />*/}
            {/*  </Button>*/}
            {/*</Stack>*/}

            {/*<Divider sx={{ my: 3 }}>*/}
            {/*  <Typography variant="body2" sx={{ color: "text.secondary" }}>*/}
            {/*    OR*/}
            {/*  </Typography>*/}
            {/*</Divider>*/}

            <Formik
              initialValues={{ email: "", password: "" }}
              // eslint-disable-next-line react/jsx-no-bind
              onSubmit={onSubmit}
              validationSchema={LoginSchema}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                touched,
                errors,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Stack direction="column" spacing={2}>
                    <TextField
                      name="email"
                      type="email"
                      label="Email"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      fullWidth
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      sx={{ mb: 2 }}
                    />
                    <TextField
                      name="password"
                      type={showPassword ? "text" : "password"}
                      label="Password"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      fullWidth
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              <Iconify
                                icon={
                                  showPassword
                                    ? "eva:eye-off-outline"
                                    : "eva:eye-outline"
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{ mb: 2 }}
                    />
                    <Typography variant="body2" sx={{ mb: 5 }}>
                      New user?
                      <Link
                        variant="subtitle2"
                        sx={{ ml: "5px" }}
                        component={RouterLink}
                        to={PATH_AUTH.register}
                      >
                        Create an account
                      </Link>
                    </Typography>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      loading={signIn.loading}
                      sx={{ mt: 5, height: 50 }}
                    >
                      Sign in
                    </LoadingButton>
                  </Stack>
                </Form>
              )}
            </Formik>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
