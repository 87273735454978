import Box from "@mui/material/Box";
import { Form, Formik, useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import * as React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ImageUpload from "../ImageUpload";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";

const Input = styled(TextField)({
  height: 200,
});
export default function TestForm({ onSubmit, loading, initialValues }) {
  useEffect(() => {
    setLevel(initialValues.level);
    setSportType(initialValues.sportType);
    setLvlOne(initialValues.lvlOne);
    setLvlTwo(initialValues.lvlTwo);
    setAnswers(initialValues.answers);
  }, [initialValues]);

  const [level, setLevel] = useState(initialValues.level);
  const [sportType, setSportType] = useState(initialValues.sportType);
  const [lvlOne, setLvlOne] = useState(initialValues.lvlOne);
  const [lvlTwo, setLvlTwo] = useState(initialValues.lvlTwo);
  const [answers, setAnswers] = useState(initialValues.answers);
  const handleLvlOne = (event) => {
    setLvlOne(event.target.value);
  };
  const handleLvlTwo = (event) => {
    setLvlTwo(event.target.value);
  };
  const handleLvl = (event) => {
    setLevel(event.target.value);
  };
  const handleType = (event) => {
    setSportType(event.target.value);
  };
  const handleAnswers = (event) => {
    const { name, value } = event.target;

    // Update the state of the corresponding answer
    setAnswers((prevAnswers) => {
      // Find the index of the answer in the array based on the label
      const index = answers.findIndex((answer) => answer.label === name);

      if (index === -1) {
        // If the answer doesn't exist in the array, add it
        return [...prevAnswers, { label: name, value }];
      } else {
        // If the answer already exists, update its value
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[index] = { label: name, value };
        return updatedAnswers;
      }
    });
  };
  function handleSubmit(data, actions) {
    const newData = new FormData();
    newData.append("questionName", data.questionName);
    newData.append("image", data.image);
    newData.append("level", level);
    newData.append("sportType", sportType);
    if (data.correctAnswer) {
      newData.append("correctAnswer", data.correctAnswer);
    }
    if (lvlOne && lvlOne.length > 0) {
      newData.append("correctAnswer", lvlOne);
    }
    if (lvlTwo && lvlTwo.length > 0) {
      newData.append("correctAnswer", lvlTwo);
    }
    answers.map((i) => {
      newData.append("answers", i.value);
    });
    onSubmit(newData, actions);
  }
  return (
    <Box>
      <Formik
        initialValues={initialValues}
        // eslint-disable-next-line react/jsx-no-bind
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, touched, errors }) => (
          <Form>
            <Box
              sx={{
                margin: "20px 0",
                display: "flex",
                alignItem: "center",
                flexDirection: "column",
              }}
            >
              <ImageUpload name="image" />
              <TextField
                id="question"
                name="questionName"
                label="Question Name"
                value={values.questionName}
                onChange={handleChange}
                fullWidth
                multiline
                rows={8}
              />
              <Box sx={{ display: "flex" }}>
                <FormControl sx={{ width: "200px", marginTop: "20px" }}>
                  <InputLabel id="demo-simple-select-label">
                    Select Level
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="Competition"
                    onChange={handleLvl}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  sx={{ width: "200px", marginTop: "20px", marginLeft: "50px" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Select Sport type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="Competition"
                    onChange={handleType}
                  >
                    <MenuItem value="fx">fx</MenuItem>
                    <MenuItem value="ph">ph</MenuItem>
                    <MenuItem value="sr">sr</MenuItem>
                    <MenuItem value="vt">vt</MenuItem>
                    <MenuItem value="pb">pb</MenuItem>
                    <MenuItem value="hb">hb</MenuItem>
                    {/*<MenuItem value={4}>C4</MenuItem>*/}
                  </Select>
                </FormControl>
              </Box>
              {(level === 1 || level === 12) && (
                <Box sx={{ paddingTop: "20px" }}>
                  <FormControl
                    sx={{
                      width: "300px",
                      marginTop: "20px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Select Level 1 correct answer
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Competition"
                      onChange={handleLvlOne}
                    >
                      <MenuItem value="A">A</MenuItem>
                      <MenuItem value="B">B</MenuItem>
                      <MenuItem value="C">C</MenuItem>
                      <MenuItem value="D">D</MenuItem>
                      <MenuItem value="E">E</MenuItem>
                      <MenuItem value="F">F</MenuItem>
                      <MenuItem value="G">G</MenuItem>
                      <MenuItem value="H">H</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}
              {(level === 2 || level === 12) && (
                <Box sx={{ paddingTop: "20px" }}>
                  <FormControl
                    sx={{
                      width: "300px",
                      marginTop: "20px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Select Level 2 correct answer
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Competition"
                      onChange={handleLvlTwo}
                    >
                      <MenuItem value="I">I</MenuItem>
                      <MenuItem value="II">II</MenuItem>
                      <MenuItem value="III">III</MenuItem>
                      <MenuItem value="IV">IV</MenuItem>
                      {/*<MenuItem value={4}>C4</MenuItem>*/}
                    </Select>
                  </FormControl>
                </Box>
              )}
              {level === 3 && (
                <Box
                  sx={{ display: "flex", flexWrap: "wrap", paddingTop: "20px" }}
                >
                  <Typography>Level 3 Answers</Typography>
                  <TextField
                    sx={{ marginTop: "20px" }}
                    id="question1"
                    name="Answer 1"
                    label="Answer 1"
                    value={
                      answers.find((answer) => answer.label === "Answer 1")
                        ?.value || ""
                    }
                    onChange={handleAnswers}
                    fullWidth
                  />
                  <TextField
                    sx={{ marginTop: "20px" }}
                    id="question2"
                    name="Answer 2"
                    label="Answer 2"
                    value={
                      answers.find((answer) => answer.label === "Answer 2")
                        ?.value || ""
                    }
                    onChange={handleAnswers}
                    fullWidth
                  />
                  <TextField
                    sx={{ marginTop: "20px" }}
                    id="question3"
                    name="Answer 3"
                    label="Answer 3"
                    value={
                      answers.find((answer) => answer.label === "Answer 3")
                        ?.value || ""
                    }
                    onChange={handleAnswers}
                    fullWidth
                  />
                  <TextField
                    sx={{ marginTop: "20px" }}
                    id="question4"
                    name="Answer 4"
                    label="Answer 4"
                    value={
                      answers.find((answer) => answer.label === "Answer 4")
                        ?.value || ""
                    }
                    onChange={handleAnswers}
                    fullWidth
                  />
                  <TextField
                    sx={{ marginTop: "20px" }}
                    id="question"
                    name="correctAnswer"
                    label="Correct answer"
                    value={values.question}
                    onChange={handleChange}
                    fullWidth
                  />
                </Box>
              )}

              <LoadingButton
                loading={loading}
                type="submit"
                variant="contained"
                sx={{
                  width: 180,
                  height: 50,
                  color: "#fcfcfc",
                  float: "right",
                  mt: 2,
                }}
              >
                Create
              </LoadingButton>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
