// ----------------------------------------------------------------------

import { useSelector } from "react-redux";

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";
const ROOTS_COMPETITION = "/competition";
const ROOTS_PROTOCOL = "/protocol";
const ROOTS_USER = "/user";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
};

export const PATH_PAGE = {
  page403: "/403",
  page404: "/404",
  page500: "/500",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
  },
  permissionDenied: path(ROOTS_DASHBOARD, "/permission-denied"),
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
  },
  competition: {
    root: path(ROOTS_COMPETITION, "/select"),
  },
  protocol: {
    root: path(ROOTS_PROTOCOL, "/select"),
  },
};
export const PATH_COMPETITION = {
  root: ROOTS_COMPETITION,
  competition: {
    root: path(ROOTS_COMPETITION, "/"),
    detail: path(ROOTS_COMPETITION, "/detail"),
    protocol: path(ROOTS_COMPETITION, "/protocol"),
    result: path(ROOTS_COMPETITION, "/result"),
    chooseMag: path(ROOTS_COMPETITION, "/choose-mag"),
    chooseWag: path(ROOTS_COMPETITION, "/choose-wag"),
  },
};
export const PATH_USER = {
  root: ROOTS_USER,
  user: {
    allUser: path(ROOTS_USER, "/all-user"),
    create: path(ROOTS_USER, "/create"),
  },
};
export const PATH_PROTOCOL = {
  root: ROOTS_PROTOCOL,
  protocolList: {
    root: path(ROOTS_PROTOCOL, "/select"),
    create: path(ROOTS_PROTOCOL, "/create"),
    list: path(ROOTS_PROTOCOL, "/list"),
    detail: path(ROOTS_PROTOCOL, "/protocol-detail"),
    assessment: path(ROOTS_PROTOCOL, "/assessment"),
    competitionSelect: path(ROOTS_PROTOCOL, `/competition-select`),
  },
};
export const PATH_DOCS = "";
export const API_DOCS = "";
