import Box from "@mui/material/Box";
import { Form, Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import * as React from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function ProtocolTypeForm({ onSubmit, loading, initialValues }) {
  function handleSubmit(data, actions) {
    const newData = new FormData();
    newData.append("name", data.name);
    onSubmit(newData, actions);
  }
  const initialName =
    initialValues && initialValues.name ? initialValues.name : "";

  return (
    <Box>
      <Formik
        initialValues={{
          name: initialName,
        }}
        enableReinitialize
        // eslint-disable-next-line react/jsx-no-bind
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, touched, errors }) => (
          <Form>
            <Box
              sx={{
                margin: "20px 0",
                display: "flex",
                alignItem: "center",
                flexDirection: "column",
              }}
            >
              <TextField
                id="question"
                name="name"
                label="Protocol Name"
                value={values.name}
                onChange={handleChange}
                fullWidth
                multiline
                rows={8}
              />
              <Box>
                <LoadingButton
                  loading={loading}
                  type="submit"
                  variant="contained"
                  sx={{
                    ml: 2,
                    width: 180,
                    height: 50,
                    color: "#fcfcfc",
                    float: "right",
                    mt: 2,
                  }}
                >
                  Create
                </LoadingButton>
                <Button
                  to="/protocol-type"
                  component={RouterLink}
                  variant="contained"
                  sx={{
                    width: 180,
                    height: 50,
                    color: "#fcfcfc",
                    float: "right",
                    mt: 2,
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
