const initialState = {
  protocolId: null,
  protocolType: null,
  protocolCompetition: null,
  protocolName: null,
};

const SET_PROTOCOL_ID = "SET_PROTOCOL_ID";
const SET_PROTOCOL_TYPE = "SET_PROTOCOL_TYPE";
const SET_PROTOCOL_COMPETITION = "SET_PROTOCOL_COMPETITION";
const SET_PROTOCOL_NAME = 'SET_PROTOCOL_NAME'
export const protocolSlice = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROTOCOL_ID:
      return {
        ...state,
        protocolId: action.payload,
      };
    case SET_PROTOCOL_TYPE:
      return {
        ...state,
        protocolType: action.payload,
      };
    case SET_PROTOCOL_COMPETITION:
      return {
        ...state,
        protocolCompetition: action.payload,
      };
    case SET_PROTOCOL_NAME:
      return {
        ...state,
        protocolName: action.payload,
      };
    default:
      return state;
  }
};

export const setProtocolId = (payload) => ({
  type: SET_PROTOCOL_ID,
  payload,
});
export const setProtocolType = (payload) => ({
  type: SET_PROTOCOL_TYPE,
  payload,
});
export const setProtocolCompetition = (payload) => ({
  type: SET_PROTOCOL_COMPETITION,
  payload,
});
export const setProtocolName = (payload) => ({
  type: SET_PROTOCOL_NAME,
  payload,
});
