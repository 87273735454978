import React from "react";
import { Container } from "@mui/material";
import useSettings from "../../hooks/useSettings";
import Page from "../../components/common/Page";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { ATHLETES_DETAIL, PROTOCOL_TYPE_ITEM } from "../../urls";
import { useLoad, usePutRequest } from "../../hooks/request";
import { useNavigate, useParams } from "react-router-dom";
import ProtocolTypeForm from "./ProtocolTypeForm";

export default function ProtocolTypeUpdate() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const url = PROTOCOL_TYPE_ITEM.replace("{id}", id);
  const update = usePutRequest({ url });
  const user = useLoad({
    url: PROTOCOL_TYPE_ITEM.replace("{id}", id),
  });
  const selectedProtocol = user.response ? user.response : [];
  async function onSubmit(data) {
    const { success } = await update.request({ data });
    if (success) {
      navigate("/protocol-type");
      enqueueSnackbar("Update success", {
        variant: "success",
      });
    }
  }
  return (
    <Page title="Test">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <Typography variant="h3">Test Form</Typography>
        <ProtocolTypeForm
          onSubmit={onSubmit}
          loading={update.loading}
          initialValues={selectedProtocol}
        />
      </Container>
    </Page>
  );
}
