import { useNavigate } from "react-router-dom";

export function auth() {
  return {
    headers: {
      Authorization: localStorage.token
        ? `Token ${localStorage.token}`
        : undefined,
    },
  };
}

let permissions;

export function checkPermission(permission) {
  if (!permissions) {
    permissions = JSON.parse(localStorage.getItem("permissions"));
  }
  return permissions.includes(permission);
}

export function clearPermissions() {
  permissions = undefined;
}

function redirectPage(groups) {
  // eslint-disable-next-line no-restricted-syntax
  for (const group of groups) {
    if (group === "admin") return "/statistics/finance";
    if (group === "seller") return "/project/projects";
    if (group === "developer" || group === "team_lead") return "/task/projects";
    if (group === "accountant") return "/finance/wallet";
    if (group === "hr") return "/staff/employee";
  }
  return "/login";
}

export function signin(
  { user, token, permissions: userPermissions, groups },
  navigate
) {
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("token", token);
  localStorage.setItem("permissions", JSON.stringify(userPermissions));
  navigate.push(redirectPage(groups));
}

export function signOut() {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("permissions");
  localStorage.removeItem("email");
  localStorage.removeItem("roleId");
  localStorage.removeItem("userType");
  localStorage.removeItem("language");
  clearPermissions();
}

export function isAuthenticated() {
  return (
    localStorage.getItem("user") &&
    localStorage.getItem("token") &&
    localStorage.getItem("permissions")
  );
}

export function getUser() {
  return JSON.parse(localStorage.getItem("user"));
}
