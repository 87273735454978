import { CardActionArea, Container, Grid, IconButton } from "@mui/material";
import useSettings from "../../hooks/useSettings";
// routes
// components
import Page from "../../components/common/Page";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import Card from "@mui/material/Card";
import { Link as RouterLink } from "react-router-dom";
import Box from "@mui/material/Box";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import React from "react";
import { useLoad } from "../../hooks/request";
import { PROTOCOL_LIST, PROTOCOL_TYPE } from "../../urls";
import { PATH_PROTOCOL } from "../../routes/paths";
import LoadingScreen from "../../components/common/LoadingScreen";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";
export default function ProtocolCardsList(props) {
  const { themeStretch } = useSettings();
  const protocolList = useLoad({ url: PROTOCOL_TYPE });
  const protocolCard = protocolList.response
    ? protocolList.response.results
    : [];
  console.log(protocolCard);
  return (
    <Page title="Protocol">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading="Protocol List"
          links={[
            { name: "Protocol", href: PATH_PROTOCOL.root },
            { name: "Protocol List", href: PATH_PROTOCOL.protocolList.list },
          ]}
        />
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {protocolList.loading ? (
            <LoadingScreen />
          ) : (
            protocolCard.map((item, index) => (
              <Card
                key={item.id}
                sx={{
                  height: "150px",
                  textDecoration: "none",
                  mt: "25px",
                  cursor: "pointer",
                  // height: 210,
                  background: "#919EAB1F",
                  ":hover": {
                    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
                  },
                  mr: 3,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardActionArea
                  component={RouterLink}
                  to={`/protocol/list/${item.id}`}
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <FolderIcon style={{ marginRight: "15px" }} />
                    <Typography gutterBottom variant="h5" component="div">
                      {item.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))
          )}

          <Card
            component={RouterLink}
            to="/protocol/create"
            sx={{
              mt: "25px",
              cursor: "pointer",
              width: 350,
              minHeight: 150,
              background: "#919EAB1F",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              ":hover": {
                boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <ControlPointIcon sx={{ fontSize: "50px", color: "#637381" }} />
          </Card>
        </Box>
      </Container>
    </Page>
  );
}
