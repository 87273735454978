import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'

export default function Modal({ isActive, children, onClose, right, style }) {
    useEffect(() => {
        const handleKeyboard = (event) => {
            if (event.keyCode === 27) {
                onClose()
            }
        }
        window.addEventListener('keydown', handleKeyboard)
        return () => {
            window.removeEventListener('keydown', handleKeyboard)
        }
    }, [onClose])

    return createPortal(
        (
            <div className="modal">
                <div className="modal-card border bg-white relative p-7">
                    <div className="modal-card-body !p-0 scrollable-element">
                        {children}
                    </div>
                </div>
            </div>
        ), document.querySelector('#modals-root'),
    )
}
