import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
// hooks
// routes
import { PATH_DASHBOARD } from '../routes/paths'
// components
import LoadingScreen from '../components/common/LoadingScreen'
import { isAuthenticated } from '../utils/auth'

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
    children: PropTypes.node,
}

export default function GuestGuard({ children }) {
    if (isAuthenticated()) {
        return <Navigate to={PATH_DASHBOARD.root} />
    }

    return <>{children}</>
}
