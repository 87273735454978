import React, { useContext } from "react";
import {
  PATH_COMPETITION,
  PATH_DASHBOARD,
  PATH_PROTOCOL,
  PATH_USER,
} from "../../../routes/paths";
import SvgIconStyle from "../../../components/common/SvgIconStyle";
import Assigment from "@mui/icons-material/Assignment";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import SchoolIcon from "@mui/icons-material/School";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import { GlobalContext } from "../../../contexts/GlobalContext";
import ClearAllOutlinedIcon from "@mui/icons-material/ClearAllOutlined";
const getIcon = (name) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 24, height: 24 }}
  />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  booking: getIcon("ic_booking"),
  invoice: getIcon("ic_invoice"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  menuItem: getIcon("ic_menu_item"),
};

export const getNavConfig = (userType) => {
  const baseNavConfig = [
    { title: "home", path: PATH_DASHBOARD.general.app, icon: ICONS.menuItem },
  ];

  const userNavConfig = [
    {
      title: "competition",
      path: PATH_COMPETITION.root,
      icon: <EmojiEventsOutlinedIcon />,
    },
    { title: "Education", path: "/education", icon: <SchoolIcon /> },
    {
      title: "Test for judges",
      path: "/test/testing",
      icon: <PendingActionsIcon />,
    },
  ];

  const adminNavConfig = [
    {
      title: "athletes",
      path: PATH_USER.user.allUser,
      icon: <GroupsOutlinedIcon width={24} height={24} />,
    },
    { title: "protocol", path: PATH_PROTOCOL.root, icon: <Assigment /> },
    {
      title: "competition",
      path: PATH_COMPETITION.root,
      icon: <EmojiEventsOutlinedIcon />,
    },
    { title: "Education", path: "/education", icon: <SchoolIcon /> },
    {
      title: "Test for judges",
      path: "/test/testing",
      icon: <PendingActionsIcon />,
    },
    {
      title: "Test List",
      path: "/test/list",
      icon: <PendingActionsIcon />,
    },
    {
      title: "Protocol Names",
      path: "/protocol-type",
      icon: <ClearAllOutlinedIcon />,
    },
  ];

  const judgeNavConfig = [
    { title: "protocol", path: PATH_PROTOCOL.root, icon: <Assigment /> },
    {
      title: "competition",
      path: PATH_COMPETITION.root,
      icon: <EmojiEventsOutlinedIcon />,
    },
    {
      title: "Test for judges",
      path: "/test/testing",
      icon: <PendingActionsIcon />,
    },
    {
      title: "Protocol Names",
      path: "/protocol-type",
      icon: <ClearAllOutlinedIcon />,
    },
  ];

  let roleNavConfig = [];

  switch (userType) {
    case "user":
      roleNavConfig = userNavConfig;
      break;
    case "admin":
      roleNavConfig = adminNavConfig;
      break;
    case "referee":
      roleNavConfig = judgeNavConfig;
      break;
    default:
      break;
  }

  return [
    {
      subheader: "",
      items: [...baseNavConfig, ...roleNavConfig],
    },
  ];
};
