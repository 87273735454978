export const SIGNIN = "/users/login";
export const REGISTER = "/users/register";
export const PROFILE = "/users/profile";
export const ATHLETES_LIST = "/athletes/athletes-list";
export const PROTOCOL_ATHLETES_LIST = "/athletes/protocol/{id}";
export const ATHLETES_DETAIL = "/athletes/athletes/{id}";
export const PROTOCOL_RESULT = "/score/result/{protocol_id}/{c_type}";
export const PROTOCOL_LIST = "/athletes/protocols";
export const PROTOCOL_LIST_ITEM = "/athletes/protocol-type/{id}";
export const PROTOCOL_TEAM = "/score/team-c4";
export const PROTOCOL_DETAIL = "/athletes/protocol/{id}";
export const PROTOCOL_SCORE_LIST = "score/protocol-score-list";
export const PROTOCOL_SCORE_DETAIL = "score/protocol-score-add/{id}";
export const COMPETITION_LIST = "/athletes/competition";
export const COMPETITION_DETAIL = "/athletes/competition/{id}";
export const COUNTRY_LIST = "/country-regions/country";
export const REGION_LIST = "/country-regions/region";
export const UZBEKISTAN_REGION_LIST = "/country-regions/uzbekistan-regions";
export const USERS = "/participants/participants-list";
export const TEAM_DELETE = "/score/team-delete";
export const TEST_CREATE = "/tests/levels";
export const TEST_LIST = "/tests/levels";
export const TEST_ITEM = "/tests/levels/{id}";
export const PROTOCOL_TYPE_ITEM = "/athletes/protocol-type/{id}";
export const PROTOCOL_TYPE = "/athletes/protocol-type";
const backendUrl =
  process.env.REACT_APP_BASE_URL ||
  `${window.location.protocol}//${window.location.hostname}`;
export const domain = backendUrl.endsWith("/")
  ? backendUrl.substr(0, backendUrl.length - 1)
  : backendUrl;
