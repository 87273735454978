import React from "react";
import Router from "./routes";
import ThemeProvider from "./theme";
import ThemeSettings from "./components/common/settings";
import { ChartStyle } from "./components/common/chart";
import ScrollToTop from "./components/common/ScrollToTop";
import { ProgressBarStyle } from "./components/common/ProgressBar";
import NotistackProvider from "./components/common/NotistackProvider";
import MotionLazyContainer from "./components/common/animate/MotionLazyContainer";
import "../node_modules/semantic-ui-css/semantic.min.css";

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
