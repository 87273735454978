import React, { createContext, useState } from 'react'
import { usePersistState } from '../hooks/state'
import { clearPermissions } from '../utils/auth'

export const GlobalContext = createContext()

export function GlobalProvider({ children }) {
    const [lang, setLang] = usePersistState('language', 'ru')
    const [carts, setCarts] = useState(JSON.parse(localStorage.getItem('carts') || '[]'))
    const [cartCount, setCartCount] = useState(JSON.parse(localStorage.getItem('cartCount') || 0))
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || {})
    const [email, setEmail] = usePersistState('email', '')
    const [roleId, setRoleId] = usePersistState('roleId', 0)
    const [userType, setUserType] = usePersistState('userType', '')

    function signin({
        user: newUser,
        token,
        permissions: userPermissions,
    }) {
        localStorage.setItem('user', JSON.stringify(newUser))
        setUser(newUser)
        localStorage.setItem('token', token)
        localStorage.setItem('language', 'ru')
        localStorage.setItem('permissions', JSON.stringify(userPermissions))
    }

    function signOut() {
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        localStorage.removeItem('permissions')
        setUser({})
        clearPermissions()
    }

    return (
        <GlobalContext.Provider value={{
            lang,
            setUser,
            setLang,
            setCarts,
            carts,
            signin,
            signOut,
            roleId,
            setRoleId,
            cartCount,
            setEmail,
            email,
            user,
            userType,
            setUserType,
            setCartCount,
        }}>
            {children}
        </GlobalContext.Provider>
    )
}
