import React, { useEffect, useState } from 'react'

export default function Message({ text, className, closeMessage, delay = 3 }) {
    const [hiding, setHiding] = useState(false)

    useEffect(() => {
        const hidingTimeout = setTimeout(() => setHiding(true), delay * 1000)
        const hideTimeout = setTimeout(closeMessage, (delay + 1) * 1000)

        return () => {
            clearTimeout(hidingTimeout)
            clearTimeout(hideTimeout)
        }
    }, [closeMessage, delay])

    return (
        <article className="message">
            <div className="message-body">
                <span>{text}</span> &nbsp; &nbsp;
                {/* <button className="delete is-pulled-right" onClick={closeMessage} /> */}
            </div>
        </article>
    )
}
