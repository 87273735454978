import React from "react";
import { useFormikContext } from "formik";
import { Button, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const ImageBox = styled("div")`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 6px;
  border: 1px solid rgba(145, 158, 171, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageUpload = ({ name }) => {
  const { setFieldValue, values } = useFormikContext();

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setFieldValue(name, file);
    } else {
      setFieldValue(name, null);
    }
  };

  return (
    <Box sx={{ display: "flex", marginBottom: "40px" }}>
      <label htmlFor="contained-button-file">
        {values && values[name] ? (
          <img src={URL.createObjectURL(values[name])} alt="preview" />
        ) : (
          <ImageBox>
            <Typography>Upload Image</Typography>
          </ImageBox>
        )}
      </label>
      <input
        accept="image/*"
        id="contained-button-file"
        type="file"
        hidden
        onChange={handleImageChange}
      />
    </Box>
  );
};

export default ImageUpload;
